import invariant from 'invariant'
import React from 'react'

import { Spacings } from '@contracts/enums/Spacings'
import { Session } from '@contracts/types/Session'

import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { getHashedReceiptUrl } from '@pure/libs/ReceiptHelper'
import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import config from '@pay/Config'
import { captureAndNotifyError } from '@pay/libs/ErrorHelper'
import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'

export function ReceiptViewEmailButton(props: { onClick: () => unknown }) {
  const [loading, setLoading] = React.useState(false)

  const onClick = () =>
    Promise.resolve(setLoading(true))
      .then(() => props.onClick())
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  return (
    <Button onClick={onClick} fullWidth disabled={loading}>
      <Box direction="row" align="center">
        <Box right spacing={Spacings.xs}>
          <FigmaImageContainer imageKey={Images.mailIcon} />
        </Box>
        <FigmaTextLoader
          textKey={Texts.textButtonsReceiptSave}
          text={getFigmaTextFromVariable(Variables['VariableID:734:12123'])}
          loading={loading}
        />
      </Box>
    </Button>
  )
}

export function ReceiptViewSMSButton(props: { onClick: () => unknown }) {
  const [loading, setLoading] = React.useState(false)

  const onClick = () =>
    Promise.resolve(setLoading(true))
      .then(() => props.onClick())
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  return (
    <Button onClick={onClick} fullWidth disabled={loading}>
      <Box direction="row" align="center">
        <Box right spacing={Spacings.xs}>
          <FigmaImageContainer imageKey={Images.shareIcon} />
        </Box>
        <FigmaTextLoader
          textKey={Texts.textButtonsReceiptSave}
          text={getFigmaTextFromVariable(Variables['VariableID:734:12122'])}
          loading={loading}
        />
      </Box>
    </Button>
  )
}

export function ReceiptViewShareButton({ session }: { session: Session }) {
  const sessionId = session?.id?.toString()
  return (
    <Button
    
      onClick={() => {
        invariant(sessionId, 'sessionId is required')
        navigator.share({ url: getHashedReceiptUrl({ sessionId, config }) })
      }}
    >
      <Box direction="row" align="center">
        <Box right spacing={Spacings.xs}>
          <FigmaImageContainer imageKey={Images.shareIcon} />
        </Box>
        <FigmaText textKey={Texts.textButtonsReceiptSave} />
      </Box>
    </Button>
  )
}
