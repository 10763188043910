import { Column } from '@react-email/column'
import { Row } from '@react-email/row'
import { Section } from '@react-email/section'

import { YYYY_MM_DD } from '@consts/DateConsts'

import PublicAssets from '@contracts/enums/PublicAssets'
import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Session } from '@contracts/types/Session'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { DayJS } from '@pure/libs/DayJsHelper'
import { getReceiptWidth } from '@pure/libs/EmailComponentHelper'
import { getMaxAllowedFreeDurationForSession } from '@pure/libs/getFreeDurationMinutesForSession'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import FigmaText from '@web-components/shared/FigmaText'
import { getFigmaText } from '@web-components/shared/TextRepository'

import { EmailBox as Box, EmailBox, EmailBoxProps } from './EmailBox'
import { EmailGreyLine } from './EmailGreyLine'
import { EmailImage } from './EmailImage'
import { EmailReceiptPaymentInformation } from './EmailReceiptPaymentInformation'
import { EmailReceiptSection } from './EmailReceiptSection'
import { EmailReceiptSummary } from './EmailReceiptSummary'
import { HardCodedTexts } from './HardCodedTexts'

export function EmailReceipt({
  totalCostElement,
  isDesktop,
  isEmailClient,
  enableSupportFooter,
  buttonElement,
  session,
  Container = EmailBox
}: {
  isDesktop: boolean
  totalCostElement?: JSX.Element
  buttonElement?: JSX.Element
  isEmailClient?: boolean
  enableSupportFooter?: boolean
  enableEmailFooter?: boolean
  session: Session
  Container?: React.FC<EmailBoxProps>
}) {
  const width = getReceiptWidth({ isDesktop, isEmailClient })
  const freeDurationMinutes = getMaxAllowedFreeDurationForSession(session)

  return (
    <Section style={{ width }}>
      <Container fullWidth>
        <Box fullWidth>
          <Row>
            <Column align="center">
              <EmailImage imageKey={Images.receiptDrifterLogo} publicAsset={PublicAssets.ReceiptDrifterLogo} />
            </Column>
          </Row>

          <Box top fullWidth alignText="center">
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptPositiveMessage} />
          </Box>
          {totalCostElement && (
            <Box top fullWidth>
              {totalCostElement}
            </Box>
          )}
          <Box top fullWidth>
            <FigmaText textKey={Texts.textPaymentsReceiptReceiptParkingDetailsHeader} />
            {session && (
              <>
                <Box fullWidth top spacing={Spacings.xs}>
                  <Row>
                    <Column>
                      <FigmaText
                        textKey={HardCodedTexts.textPaymentsReceiptCssReceiptDetailsParkingDate}
                        text={DayJS(
                          session?.isHikerSession
                            ? session.parkingSession?.startedAt
                            : // @ts-ignore
                              session.backendSession?.park_session?.ended_at
                        ).format(YYYY_MM_DD)}
                      />
                    </Column>
                    <Column align="right">
                      <FigmaText
                        textKey={HardCodedTexts.textPaymentsReceiptCssReceiptDetailsRegNo}
                        text={
                          session?.isHikerSession
                            ? session.parkingSession?.plate
                            : // @ts-ignore
                              session.backendSession?.vehicle?.plate
                        }
                      />
                    </Column>
                  </Row>
                </Box>
                <Box fullWidth top spacing={Spacings.xs}>
                  <FigmaText
                    textKey={HardCodedTexts.textPaymentsReceiptCssReceiptDetailsStreetName}
                    // @ts-ignore
                    text={session.site?.address ?? session.site?.name}
                  />
                </Box>
                {session?.isHikerSession && freeDurationMinutes && (
                  <Box fullWidth top spacing={Spacings.xs}>
                    <FigmaText
                      textKey={HardCodedTexts.textPaymentsReceiptFreeParking}
                      text={formatText(getFigmaText(HardCodedTexts.textPaymentsReceiptFreeParking), [
                        freeDurationMinutes
                      ])}
                    />
                  </Box>
                )}
                <Box top spacing={Spacings.s} fullWidth>
                  <EmailGreyLine color={Colors.neutral1} />
                </Box>
              </>
            )}

            <Box top spacing={Spacings.s} fullWidth>
              <EmailReceiptSection
                textKey={Texts.textActiveSessionParkingInformationInformationScreenSection1Header}
                session={session}
              />
            </Box>
            <Box top spacing={Spacings.s} fullWidth>
              <EmailGreyLine color={Colors.neutral1} />
            </Box>
            <Box top spacing={Spacings.s} fullWidth>
              <EmailReceiptSummary session={session} />
            </Box>
            <Box top spacing={Spacings.s} fullWidth>
              <EmailGreyLine color={Colors.neutral1} />
            </Box>
            <Box top spacing={Spacings.s} fullWidth>
              <EmailReceiptPaymentInformation sessionPublic={session} />
            </Box>
          </Box>
          {!!buttonElement && (
            <Box top spacing={Spacings.xl} fullWidth>
              <Row>
                <Column align="center">{buttonElement}</Column>
              </Row>
            </Box>
          )}
        </Box>
      </Container>
      {enableSupportFooter && (
        <Box top spacing={Spacings.xl} fullWidth>
          <EmailReceiptSupportFooter />
        </Box>
      )}
    </Section>
  )
}

export const TotalCostContainerStyle = {
  borderRadius: BorderRadiusesPx.rounded,
  border: `1px solid ${Colors.gray200})`,
  background: Colors.purple0,
  boxShadow: `0px 2px 17px 0px rgba(0, 0, 0, 0.1)`
}

function EmailReceiptSupportFooter() {
  return (
    <Box fullWidth>
      <Row>
        <Column>
          <EmailImage imageKey={Images.supportChatBubbles} publicAsset={PublicAssets.SupportChatBubbles} />
        </Column>
        <Column>
          <Box fullPadding></Box>
        </Column>
        <Column>
          <FigmaText textKey={Texts.supportTextsSupportTextWeb1} />
          <br />
          <br />
          <FigmaText textKey={Texts.supportTextsSupportTextWeb2} />
          <FigmaText textKey={Texts.supportTextsSupportTextWeb3} />
          <br />
          <FigmaText textKey={Texts.supportTextsSupportTextWeb4} />
          <br />
          <br />
          <FigmaText textKey={Texts.supportTextsSupportTextWeb5} />
        </Column>
      </Row>
    </Box>
  )
}
