import { AmidoDoorNames } from '@contracts/enums/Amido'
import { Payment } from '@contracts/types/Session'
import { SiteStaffContactDetail } from '@contracts/types/SiteStaffContactDetail'

import { Language } from '@pure/emu/Antiloop'

export interface CreateAuthTokenRequest {
  userId: number
}

export enum CloudFunctions {
  sendPermitForm = 'sendPermitForm',
  sendPermitUsersForm = 'sendPermitUsersForm',
  sendExpiredReceipt = 'sendExpiredReceipt',
  sendReceiptForSession = 'sendReceiptForSession',
  createStripeCheckoutSession = 'createStripeCheckoutSession',
  createStripeSetupIntentSession = 'createStripeSetupIntentSession',
  createFortnoxCredentials = 'createFortnoxCredentials',
  detachPaymentMethod = 'detachPaymentMethod',
  getActiveSessionForPlate = 'getActiveSessionForPlate',
  setDefaultPaymentMethod = 'setDefaultPaymentMethod',
  getSessionsForUser = 'getSessionsForUser',
  getUser = 'getUser',
  getPermitsForUser = 'getPermitsForUser',
  setExpoPushNotificationsTokenForUser = 'setExpoPushNotificationsTokenForUser',
  getOrCreateUser = 'getOrCreateUser',
  getValidPermitForSession = 'getValidPermitForSession',
  getPermitForCode = 'getPermitForCode',
  getPermitTemplateForCode = 'getPermitTemplateForCode',
  acceptUserPermitConnection = 'acceptUserPermitConnection',
  getLinkToGuestParkingFeauture = 'getLinkToGuestParkingFeauture',
  convertToFreeSession = 'convertToFreeSession',
  getSiteNames = 'getSiteNames',
  upsertStaffContactDetails = 'upsertStaffContactDetails',
  addUserToPermit = 'addUserToPermit',
  createPermitForUserFromTemplate = 'createPermitForUserFromTemplate',
  getStripePaymentMethodsForUser = 'getStripePaymentMethodsForUser',
  calculatePriceForPermit = 'calculatePriceForPermit',
  terminatePermitConnection = 'terminatePermitConnection',
  createCustomTokenForIdToken = 'createCustomTokenForIdToken',
  onSessionClosedDone = 'onSessionClosedDone',
  sendInvoiceTaskV2 = 'sendInvoiceTaskV2',
  onSessionClosed = 'onSessionClosed',
  getOrCreateUserSecure = 'getOrCreateUserSecure',
  getVehicle = 'getVehicle',
  getSessionColumnDataForSite = 'getSessionColumnDataForSite',
  getFinancialDataForSite = 'getFinancialDataForSite',
  getPermitIncomeByMonthForSite = 'getPermitIncomeByMonthForSite',
  openGate = 'openGate',
  getGuestParkingSitePermits = 'getGuestParkingSitePermits',
  getSessionPaymentInfo = 'getSessionPaymentInfo',
  getLinkToGuestParking = 'getLinkToGuestParking',
  handleGuestParking = 'handleGuestParking',
  generatePermitInvoiceTask = 'generatePermitInvoiceTask',
  addPermitStaffMember = 'addPermitStaffMember',
  enqueueGuestParkingRequest = 'enqueueGuestParkingRequest',
  onGuestParkingConversionEnqueued = 'onGuestParkingConversionEnqueued',
  userBackendGet = 'userBackendGet',
  userBackendPut = 'userBackendPut',
  userBackendDelete = 'userBackendDelete',
  getDrifterEulaHTML = 'getDrifterEulaHTML',
  upsertPermitDebtor = 'upsertPermitDebtor'
}

export type CreateCustomAuthTokenRequestForInternalUser = { uid: string }

export type PermitFormUser = { name: string; phone: string; email: string }
export type SendPermitFormRequest = {
  orgNumber: string
  orgName: string
  email: string
  phone: string
}
export type SendPermitUsersFormRequest = {
  orgName: string
  area: string
  users: PermitFormUser[]
}

export type SendReceiptRequest = { sessionId: string; email?: string; phone?: string }

export type CreateStripeCheckoutSessionRequest = { sessionId: string; successUrl: string; cancelUrl: string }

export type CreateStripCheckoutSessionResponse = {
  redirectUrl: string
  id: string
}

export type CreateFortnoxCredentialsRequest = { code: string }

export type GetActiveSessionPublicForPlateRequest = {
  plate: string
}

export type GetSessionPaymentInfoRequest = {
  plate: string
}

export type CreateStripeSetupIntentSessionResponse = {
  setupIntent: string
}

export type CreatePictureForHtmlRequest = {
  path: string
  html: string
}

export type CreateStripePaymentIntentRequest = {
  stripeCustomerId: string
  stripePaymentMethodId: string
  sessionId: string
}

export type CreateStripePaymentIntentResponse = {
  paymentIntent: string
}

export type CreateConvertLinkToFreeSessionRequest = {
  sessionId: string
}

export type CreateConvertLinkToFreeSessionResponse = {
  url: string
}

export type GetDebtorRequest = GetSessionsForUserRequest

export type SetDefaultPaymentMethodRequest = {
  userId: string
  stripePaymentMethodId: string
}
export type DetachPaymentMethodRequest = {
  userId: string
  stripePaymentMethodId: string
}

export type CreateStripeSetupIntentSessionRequest = {
  userId: string
  email: string
  phone: string
  name?: string
}

export type GetSessionsForUserRequest = {
  userId: string
  paymentStatus?: Payment['status']
}

export type GetUserRequest = {
  uid?: string // deprecated plz remove
  plates?: string[]
}

export type GetPermitsForUserRequestDeprecated = {
  deprecatedUid?: string // todo remove, should rely on auth
}

export type SetExpoPushNotificationsTokenForUser = {
  userId?: string
  expoPushNotificationsToken?: string
}

export type GetPermitForCodeRequest = {
  code: string
}

export type GetPermitTemplateForCodeRequest = {
  code: string
}

export type AcceptUserPermitConnectionRequest = {
  deprecatedUid: string
  permitId: string
}

export type UpsertStaffContactDetailsRequest = {
  siteId: string
  staffContactDetails: SiteStaffContactDetail[]
  deprecatedUid: string
}

export type AddUserToPermitRequest = {
  permitId: string
}

export type CreatePermitForUserFromTemplateRequest = {
  permitTemplateId: string
}

export type CalculatePriceForPermitRequest = {
  permitId?: string
  permitTemplateId?: string
  date?: string
}

export type PermitRequest = {
  permitId: string
  userId?: string // which user with permit connection to terminate
  source?: string // from where the termination is initiated (mobile app or my-drifter)
  updatedById?: string // who initiated the termination
}

export type CreateCustomerPortalLinkRequest = { siteId: string; permitDebtorId: string }

export type GetSecureHashRequest = {
  payload: object | Buffer
  signOptions: {
    expiresIn: string
    algorithm?: string
  }
}

export type GetOrCreateUserSecureRequest = {
  name?: string
  locale?: Language
  email?: string
  phone: string
}

export type GetVehicleRequest = {
  plate: string
}

export type GetVehicleResponse = {
  numberPlate?: string
  brandName?: string
  modelName?: string
  registeredTo?: string | string[]
}

export type GetSessionColumnDataForSiteRequest = {
  siteId: string
  fromDate: string
  toDate: string
}

export type OpenGateRequest = {
  siteId: string
  doorName?: AmidoDoorNames
  context?: string
}

export type AddPermitStaffMemberRequest = {
  permitId: string
  phone: string
}
