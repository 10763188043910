import { captureException } from '@sentry/react'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'

import { slack } from '@web-js/libs/SlackHelperClient'

export default function onUnhandledPromiseRejection(error: Error) {
  captureException(error)

  return slack(`${error.name} ${error.message} ${error.stack}`, SlackWebhooks.OPS_HIKER_PAY_QR)
}
