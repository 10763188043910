export const HardCodedTexts = {
  textPaymentsReceiptFreeParking: {
    characters: '',
    name: 'textPaymentsReceiptCssReceiptDetailsStreetName',
    texts: {
      swe: 'Gratis Parkering: XXX min per dag',
      eng: 'Free Parking: XXX min per day'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptCssReceiptDetailsParkingDate: {
    characters: '2023-09-30',
    name: 'textPaymentsReceiptCssReceiptDetailsParkingDate',
    texts: {
      swe: '2023-09-30',
      eng: '2023-09-30'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptCssReceiptDetailsRegNo: {
    characters: 'ABC 123',
    name: 'textPaymentsReceiptCssReceiptDetailsRegNo',
    texts: {
      swe: 'ABC 123',
      eng: 'ABC 123'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptCssReceiptDetailsStreetName: {
    characters: 'Importgatan 12A, Göteborg',
    name: 'textPaymentsReceiptCssReceiptDetailsStreetName',
    texts: {
      swe: 'Importgatan 12A, Göteborg',
      eng: 'Importgatan 12A, Göteborg'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptServiceFee: {
    characters: 'Service Fee',
    name: 'textPaymentsReceiptReceiptServiceFee',
    texts: {
      swe: 'Administrationsavgift',
      eng: 'Service Fee'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptPaymentWith: {
    characters: 'Paid with',
    name: 'textPaymentsReceiptReceiptPaymentWith',
    texts: {
      swe: ' Betalning med',
      eng: 'Paid with'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptTransactionId: {
    characters: 'Transaction Id',
    name: 'textPaymentsReceiptReceiptTransactionId',
    texts: {
      swe: 'Transaktionsid',
      eng: 'Transaction Id'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptCompanyName: {
    characters: 'Drifter World AB',
    name: 'textPaymentsReceiptReceiptCompanyName',
    texts: {
      swe: 'Drifter World AB',
      eng: 'Drifter World AB'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptOrgNo: {
    characters: 'Org.no: 556982-8600',
    name: 'textPaymentsReceiptReceiptOrgNo',
    texts: {
      swe: 'Org.nr: 556982-8600',
      eng: 'Org.no: 556982-8600'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptReceiptVat: {
    characters: 'VAT (25%)',
    name: 'textPaymentsReceiptReceiptVat',
    texts: {
      swe: 'Moms (25%)',
      eng: 'VAT (25%)'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textPaymentsReceiptFormatParkingPeriod: {
    characters: 'hh:mm to hh:mm (hh:mm:ss)',
    name: 'textPaymentsReceiptFormatParkingPeriod',
    texts: {
      swe: 'hh:mm till hh:mm (hh:mm:ss)',
      eng: 'hh:mm to hh:mm (hh:mm:ss)'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  }
}
