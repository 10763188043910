import { toast } from 'react-toastify'

import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import onUnhandledPromiseRejection from './onUnhandledPromiseRejection'
import { Variables } from './Variables'

export const captureAndNotifyError = (err: Error) => {
  showGenericErrorToast()

  return onUnhandledPromiseRejection(err)
}

export function showGenericErrorToast() {
  toast(getFigmaTextFromVariable(Variables['VariableID:86:763']), {
    type: 'error',
    position: 'top-center'
  })
}
