import { Column } from '@react-email/column'
import { Row } from '@react-email/row'

import { Spacings } from '@contracts/enums/Spacings'
import { Session } from '@contracts/types/Session'

import Texts from '@pure/assets/PayTexts'
import { TextKey } from '@pure/emu/Antiloop'
import { formatPrice } from '@pure/libs/ReceiptHelper'

import FigmaText from '@web-components/shared/FigmaText'
import { FigmaTextWithStyle } from '@web-components/shared/FigmaTextWithStyle'

import { EmailBox as Box } from './EmailBox'
import { getParkingPeriodText } from './EmailReceiptSectionHelper'
import { HardCodedTexts } from './HardCodedTexts'

export function EmailReceiptSection({ textKey, session }: { textKey: TextKey; session: Session }) {
  return (
    <Box fullWidth>
      <Row>
        <Column>
          <FigmaText textKey={textKey} />
        </Column>
        <Column align="right">
          <FigmaTextWithStyle
            type="body1Regular"
            textKey={Texts.textPaymentsReceiptCssReceiptPriceCharging}
            text={formatPrice(session.parkingSession?.currentPayment?.fees?.totalParkingFeeIncludingVAT)}
          />
        </Column>
      </Row>
      {session && (
        <Box top spacing={Spacings.xs} fullWidth>
          <FigmaText
            textKey={HardCodedTexts.textPaymentsReceiptFormatParkingPeriod}
            text={getParkingPeriodText(session)}
          />
        </Box>
      )}
    </Box>
  )
}
